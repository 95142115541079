<section class="h-full flex flex-col">
  <app-modal-header title="Computations timeout" (close)="dialogRef.close()"></app-modal-header>

  <mat-dialog-content>

    <p class="mb-3">
      Each workflow execution can take up to 4 minutes and 30 seconds to complete (270 seconds). Any longer than that will result in a timeout error,
    </p>

    <p class="mb-3">
      For example, when you include OpenAI computations, DataMorf will need to do a call to their API for each computation
      that requires it. OpenAI requests will take some time to complete depending on the length of the prompt, the length
      of the generated text and the <a href="https://status.openai.com/" target="_blank" class="basic-link">current status</a> of
      their API (we don't have any control on that).
    </p>

    <p class="mb-3">
      If you keep seeing timeout errors on your executions, tests or playground, try to reduce the number of computations,
      reduce prompt size or split the computations in multiple workflows.
    </p>



  </mat-dialog-content>

  <mat-dialog-actions class="mt-auto">
    <app-modal-footer class="w-full" [save]="false" (onClose)="dialogRef.close()"></app-modal-footer>
  </mat-dialog-actions>
</section>
