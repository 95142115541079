<section class="h-full flex flex-col ">
  <app-modal-header title="Update your plan" (close)="dialogRef.close()"></app-modal-header>

  <mat-dialog-content>

    <div>
      <ng-container *ngIf="data.mode === 'upgrade'" class="mb-2">
        <p class="mb-2">
          <span class="text-xl">🎉</span>
          Congratulations!
        </p>
        <p class="mb-2">
          You are one step away from overcharging your DataMorf workspace. You are about to get the tier
          <strong class="uppercase">{{data.tier.name}}</strong>.
        </p>
      </ng-container>
      <ng-container *ngIf="data.mode === 'downgrade'" class="mb-2">
        <p class="mb-2">
          You are about to downgrade your workspace tier to
          <strong class="uppercase">{{data.tier.name}}</strong>.
        </p>
      </ng-container>

      <p class="mb-2">
        As a safety measure, please write the tier's name to confirm.
      </p>
      <p class="text-center my-2">
        <span class="bg-gray-100 text-gray-600 p-2 rounded-xl">{{data.tier.name | uppercase}}</span>
      </p>

      <mat-form-field class="w-full mb-3" appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput [placeholder]="data.tier.name" [(ngModel)]="input">
      </mat-form-field>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions class="mt-auto">
    <app-modal-footer class="w-full" [confirm]="true" (onConfirm)="confirm()"
                      (onClose)="dialogRef.close()"></app-modal-footer>
  </mat-dialog-actions>
</section>
