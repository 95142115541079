import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {WorkspaceInterface} from "../../../interfaces/workspace.interface";


import {CurrencyPipe, DatePipe, NgIf} from "@angular/common";
import {ModalFooterComponent} from '../../../layout/modal-footer/modal-footer.component';
import {ModalHeaderComponent} from '../../../layout/modal-header/modal-header.component';
import {AdditionalCostsInterface} from "../../../interfaces/workspace/additional-costs.interface";

@Component({
  selector: 'app-info-additional-cost-details',
  templateUrl: './info-additional-cost-details.component.html',
  styleUrls: ['./info-additional-cost-details.component.scss'],
  imports: [
    MatDialogModule,
    ModalFooterComponent,
    ModalHeaderComponent,
    NgIf,
    CurrencyPipe,
    DatePipe
],
  standalone: true
})
export class InfoAdditionalCostDetailsComponent {
  constructor(
    public dialogRef: MatDialogRef<InfoAdditionalCostDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      additionalCost: AdditionalCostsInterface,
    },
  ) { }
}
