import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialogTitle} from '@angular/material/dialog';

@Component({
    selector: 'app-modal-header',
    templateUrl: './modal-header.component.html',
    styleUrls: ['./modal-header.component.scss'],
    standalone: true,
    imports: [MatDialogTitle]
})
export class ModalHeaderComponent implements OnInit {
  @Input() title: string
  @Output() close = new EventEmitter()
  constructor() { }

  ngOnInit(): void {
  }

}
