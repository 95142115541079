import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {ModalHeaderComponent} from '../../../layout/modal-header/modal-header.component';
import {CdkScrollable} from '@angular/cdk/scrolling';
import {NgSwitch, NgSwitchCase} from '@angular/common';
import {ModalFooterComponent} from '../../../layout/modal-footer/modal-footer.component';

@Component({
    selector: 'app-modal-info-what-is',
    templateUrl: './modal-info-what-is.component.html',
    styleUrls: ['./modal-info-what-is.component.scss'],
    standalone: true,
    imports: [ModalHeaderComponent, CdkScrollable, MatDialogContent, NgSwitch, NgSwitchCase, MatDialogActions, ModalFooterComponent]
})
export class ModalInfoWhatIsComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalInfoWhatIsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      element: string,
    },
  ) { }

  ngOnInit(): void {
  }

}
