<section class="h-full flex flex-col">
  <app-modal-header title="Book a free consultation" (close)="dialogRef.close()"></app-modal-header>

  <mat-dialog-content>
    <p class="mb-5">
      Beginnings can be complicated. We are here to help you get started with your data journey. Book a free
      consultation with us and we will reach out to you soon.
    </p>

    <form [formGroup]="form">
      <div class="flex flex-wrap justify-center space-x-4 mb-5">
        <div class="w-full mb-2">
          How do you prefer to be contacted?
        </div>
        <div class="w-auto rounded transition" [ngClass]="{'bg-gray-200': channel.value === c}" *ngFor="let c of channels">
          <div (click)="form.patchValue({channel: c})" class="cursor-pointer p-2">
            <figure>
              <img src="./assets/img/vendors/{{c}}.png" [alt]="c" class="w-14 mb-1">
              <figcaption class="text-xs text-gray-500 text-center w-full">{{c | titlecase}}</figcaption>
            </figure>
          </div>
        </div>
        <div class="w-auto rounded transition">
          <div class="cursor-pointer p-2">
            <a href="https://calendly.com/info-isdj/30min" title="Calendly link" target="_blank">
              <figure>
                <img src="./assets/img/vendors/calendly.png" alt="Calendly" class="w-14 mb-1">
                <figcaption class="text-xs text-gray-500 text-center w-full">Calendly</figcaption>
              </figure>
            </a>
          </div>
        </div>
      </div>

      <mat-form-field class="w-full" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email">
      </mat-form-field>

    </form>

    <p class="p-6 bg-accent-50 rounded-xl text-gray-800" *ngIf="error">
      <i class="fas fa-exclamation-triangle mr-1 text-red-600"></i>
      Something went wrong... Please send us an email at info&#64;datamorf.io
    </p>
    <p class="p-6 bg-secondary-50 rounded-xl text-gray-800" *ngIf="success">
      <i class="fas fa-check-circle mr-1 text-green-600"></i>
      Nice! We will reach out to you soon.
    </p>


  </mat-dialog-content>

  <mat-dialog-actions class="mt-auto">
    <app-modal-footer class="w-full" (onClose)="dialogRef.close()" [send]="!error && !success" (onSend)="submit()"
                      [processing]="submitting"></app-modal-footer>
  </mat-dialog-actions>

</section>
