import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatRipple} from '@angular/material/core';
import {NgIf} from '@angular/common';

@Component({
    selector: 'app-modal-footer',
    templateUrl: './modal-footer.component.html',
    styleUrls: ['./modal-footer.component.scss'],
    standalone: true,
    imports: [MatRipple, NgIf]
})
export class ModalFooterComponent implements OnInit {
  @Input() processing: boolean
  @Input() disabled: boolean
  @Input() save: boolean
  @Input() send: boolean
  @Input() confirm: boolean
  @Input() add: boolean
  @Input() test: boolean
  @Input() create: boolean
  @Input() run: boolean
  @Input() share: boolean
  @Input() export: boolean
  @Output() onSave = new EventEmitter()
  @Output() onConfirm = new EventEmitter()
  @Output() onClose = new EventEmitter()
  @Output() onAdd = new EventEmitter()
  @Output() onSend = new EventEmitter()
  @Output() onTest = new EventEmitter()
  @Output() onCreate = new EventEmitter()
  @Output() onRun = new EventEmitter()
  @Output() onShare = new EventEmitter()
  @Output() onExport = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

}
