<app-modal-header title="Not only hearts break, this function too..." (close)="dialogRef.close()"></app-modal-header>

<mat-dialog-content>
  <div class="text-gray-400 text-sm">
    <p class="mb-3">
      <i class="fas fa-heart-broken fa-4x text-gray-600"></i>
    </p>
    <p class="mb-3">
      Something went wrong and we could not do the action you intended. We have already collected the error message
      and sent it to our development team.
    </p>

    <div [ngSwitch]="data.modal">
      <p *ngSwitchCase="'could-not-get-valid-access-token'">
        We could not get a valid access token. Please try again or re-authenticate this integration.
      </p>

      <p class="mb-3" *ngSwitchDefault>
        You can try to refresh the page and try again, or let us know if you have any additional detail.
      </p>

    </div>
    <p class="mb-3">
      Sorry for the inconveniences.
    </p>
  </div>


</mat-dialog-content>

<mat-dialog-actions class="mt-auto">
  <app-modal-footer class="w-full" (onClose)="dialogRef.close()"></app-modal-footer>
</mat-dialog-actions>
