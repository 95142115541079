<!--<section class="h-full flex flex-col">-->
<app-modal-header title="What is output path?" (close)="dialogRef.close()"></app-modal-header>

<mat-dialog-content>
  <p>
    This computation field indicates how to structure your computed data object. You can write (using dot notation) where
    you want to store the result of your computation. It cannot contain spaces.
  </p>
  <p>
    We recommend using kebabCase or snake_case.
  </p>
  <p class="my-3">
    Let's see it with an example:
  </p>
  <p>
    If we use <code>vendor_id</code> as out output path, the resulting computed object will look like this:
  </p>
  <app-pretty-print-json [object]="{vendor_id: 'any value'}"></app-pretty-print-json>

  <p class="mt-3">
    Instead, if we use <code>properties.vendor_id</code>, the result will be:
  </p>
  <app-pretty-print-json [object]="{properties: {vendor_id: 'any value'}}"></app-pretty-print-json>


  <p class="mt-3">
    You can also create arrays with dot notation using numbers instead of letters: <code>vendor_ids.0</code>
  </p>
  <app-pretty-print-json [object]="{vendor_ids: ['any_value']}"></app-pretty-print-json>

  <p class="my-5">
    You can find more information about dot notation
    <a href="https://builtin.com/data-science/dot-notation" referrerpolicy="no-referrer" target="_blank" class="basic-link">here</a>.
  </p>

</mat-dialog-content>

<mat-dialog-actions class="mt-auto">
  <app-modal-footer class="w-full" (onClose)="dialogRef.close()"></app-modal-footer>
</mat-dialog-actions>
<!--</section>-->
