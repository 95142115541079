<!--<section class="h-full flex flex-col">-->
<app-modal-header title="Limit reached" (close)="dialogRef.close()"></app-modal-header>

<mat-dialog-content>
  <p class="">
    Your current plan doesn't allow to add more items.
  </p>
  <p>
    Please upgrade your plan to remove this restriction or contact us to find a custom deal.
  </p>


</mat-dialog-content>

<mat-dialog-actions class="mt-auto">
  <app-modal-footer class="w-full" (onClose)="dialogRef.close()"></app-modal-footer>
</mat-dialog-actions>
<!--</section>-->
