import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import {UserInterface} from "../interfaces/user/user.interface";
import { user, getAdditionalUserInfo, getAuth, createUserWithEmailAndPassword, updateProfile, sendEmailVerification, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, signOut, sendPasswordResetEmail, reauthenticateWithCredential, updatePassword, useDeviceLanguage, EmailAuthProvider } from '@angular/fire/auth';
import {User, UserCredential} from "@firebase/auth";
import {Firestore, doc, updateDoc, setDoc, getDoc} from "@angular/fire/firestore";
import {firstValueFrom} from "rxjs";
import {environment} from "../../../environments/environment";
import {CommonModalsService} from "./modals/common-modals.service";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  wrongCredentials = false;
  tooManyRequests = false;
  userNotFound = false;
  invalidEmail = false
  unknownError = '';
  error = false
  public uid: string
  public email: string
  auth = getAuth()
  user$ = user(this.auth)

  constructor(
    private router: Router,
    private firestore: Firestore,
    private commonModalSvc: CommonModalsService,
    private http: HttpClient
  ) {
    this.user$.subscribe((user) => {
      this.uid = user?.uid ?? ''
      this.email = user?.email ?? ''
    })
  }

  loginWithEmail(email: string, password: string): Promise<boolean> {
    return signInWithEmailAndPassword(this.auth, email, password)
      .then(async (credential) => {
        this.auth = getAuth()
        // TODO: enable email verification
        // if (credential.user?.emailVerified) {
        await this.router.navigate(['/'])
        return Promise.resolve(true);
      });
  }

  loginWithGoogle(reAuth: boolean = false) {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    })
    // firebase.auth().useDeviceLanguage();
    useDeviceLanguage(this.auth)
    signInWithPopup(this.auth, provider)
      .then(async (result) => {
        this.auth = getAuth()
        const additionalInfo = getAdditionalUserInfo(result)
        if (additionalInfo?.isNewUser) {
          await this.createInitialFireStructure(result)
        }
        await this.router.navigate(['/'])
      })
      .catch((err) => {
        console.error('Login error', err);
        return {
          success: false
        }
      })
  }

  logout() {
    signOut(this.auth)
      .then(async () => {
        localStorage.removeItem('lastWorkspaceUsed')
        localStorage.removeItem('workspaceId')
        await this.router.navigate(['/auth', 'login']);
      })
      .catch(err => {
        console.error('Log out error: ', err);
      });
  }

  async sendVerificationEmail() {
    useDeviceLanguage(this.auth)
    if (this.auth.currentUser) {
      await sendEmailVerification(this.auth.currentUser)
    }
  }

  async sendResetPasswordEmail(email: string) {
    useDeviceLanguage(this.auth)
    try {
      await sendPasswordResetEmail(this.auth, email)
      return true
    } catch (e) {
      return false
    }
  }

  async updateUserPassword(newPassword: string) {
    await updatePassword(this.auth.currentUser as User, newPassword)
  }

  async updateDisplayName(newName: string, uid?: string) {
    await updateProfile(this.auth.currentUser as User, {
      displayName: newName
    })
    if (uid) {
      const d = doc(this.firestore, `users/${uid}`)
      await updateDoc(d, {
        name: newName
      })
    }
  }

  public reauthenticateUserWithPassword(email: string, password: string){
    const user = this.auth.currentUser as User
    const credential = EmailAuthProvider.credential(email, password);
    return reauthenticateWithCredential(user, credential)
  }

  async signUpByEmail(name: string, email: string, password: string) {
    return createUserWithEmailAndPassword(this.auth, email, password)
      .then(async (userCredential) => {
        this.auth = getAuth()
        useDeviceLanguage(this.auth)
        if (this.auth.currentUser) {
          await updateProfile(this.auth.currentUser, {
            displayName: name
          })
        }
        await this.createInitialFireStructure(userCredential)
        await this.sendVerificationEmail()
        await this.router.navigate(['/'])
        return Promise.resolve(true)
      })
      .catch((err) => {
        return Promise.reject(err.code);
      });
  }

  async createInitialFireStructure(user: UserCredential) {
    const data: UserInterface = {
      uid: user.user?.uid ?? '',
      name: user.user?.displayName ?? '',
      email: user.user?.email ?? '',
      admin: false
    }
    const d = doc(this.firestore, `users/${data.uid}`)
    await setDoc(d, data)
  }

  errorSelector(error: {code: string, message: string}) {
    this.error = true
    switch (error.code) {
      case 'auth/invalid-email':
        this.invalidEmail = true
        break
      case 'auth/wrong-password':
        this.wrongCredentials = true
        break
      case 'auth/user-not-found':
        this.userNotFound = true
        break
      case 'auth/too-many-requests':
        this.tooManyRequests = true;
        break
      default:
        this.unknownError = error.message
    }
  }

  resetErrors() {
    this.error = false
    this.userNotFound = false
    this.wrongCredentials = false;
    this.invalidEmail = false
    this.unknownError = '';
  }

  async getUserData() {
    const d = doc(this.firestore, `users/${this.uid}`)
    const docSnap = await getDoc(d)
    if (docSnap.exists()) {
      return docSnap.data() as UserInterface
    }
    return null
  }

  async getAuthToken(vendor: string, workspaceId: string, integrationId: string, code: string, isNew = true) {
    const data = {
      code,
      mode: isNew ? 'new' : 'refresh',
      workspaceId,
      integrationId,
      fromLocalhost: location.hostname === 'localhost'
    }
    try {
      return await firstValueFrom(this.http.post(environment.fireServer + `/integrations/${vendor}/get-access-token`, data)) as {
        accessToken: string
        expiresIn: number
        refreshToken: string
        tokenType: string
      }
    } catch (e: any) {
      await this.commonModalSvc.showErrorModal(e.message, e.status, 'could-not-get-valid-access-token', `refresh ${vendor} access token`, data)
      return null
    }
  }
}
