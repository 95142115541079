import {Component, OnInit} from '@angular/core';
import {MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {ModalHeaderComponent} from '../../../layout/modal-header/modal-header.component';
import {CdkScrollable} from '@angular/cdk/scrolling';
import {ModalFooterComponent} from '../../../layout/modal-footer/modal-footer.component';

@Component({
    selector: 'app-limit-reached',
    templateUrl: './limit-reached.component.html',
    styleUrls: ['./limit-reached.component.scss'],
    standalone: true,
    imports: [ModalHeaderComponent, CdkScrollable, MatDialogContent, MatDialogActions, ModalFooterComponent]
})
export class LimitReachedComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LimitReachedComponent>,
  ) { }

  ngOnInit(): void {
  }

}
