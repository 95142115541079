<section class="h-full flex flex-col">
  <app-modal-header title="How to send data" (close)="dialogRef.close()"></app-modal-header>

  <mat-dialog-content>

    <div class="mb-2" [ngSwitch]="data.section">
      <p *ngSwitchCase="'playground'">
        To load your testing datasets into the playground you must send them one by one to the following endpoint via
        HTTP POST request:
      </p>
      <p *ngSwitchCase="'trigger'">
        To send your data to this trigger you simply need to send an HTTP POST request with your payload to:
      </p>
      <p *ngSwitchCase="'workflow'">
        To activate this workflow you need to send an HTTP POST request with your desired payload to:
      </p>
      <p *ngSwitchCase="'sample'">
        To load a sample payload send an HTTP POST request with your desired data to:
      </p>
    </div>

    <small class="break-words">
      {{url}}
    </small>

    <p class="mt-1 mb-4 text-right">
      <button (click)="helperSvc.copyText(url)" class="btn btn-sm btn-flat">
        <i class="fas fa-copy"></i>
        Copy url
      </button>
    </p>

    <div class="bg-gray-800 rounded p-3 text-white monospace break-words">
      curl <span class="text-pink-500">-X</span> POST
      <br>
      <span class="text-pink-500 mr-1">-H</span> <span class="text-amber-400">"Content-Type: application/json"</span>
      <br>
      <ng-container *ngIf="data.bearer">
        <span class="text-pink-500 mr-1">-H</span> <span class="text-amber-400">"Authorization: Bearer {{data.bearer}}"</span>
        <br>
      </ng-container>
      <span class="text-pink-500 mr-1">-d</span> <span class="text-amber-400">YOUR_PAYLOAD</span>
      <br>
      {{url}}
    </div>

  </mat-dialog-content>

  <mat-dialog-actions class="mt-auto">
    <app-modal-footer class="w-full" [save]="false" (onClose)="dialogRef.close()"></app-modal-footer>
  </mat-dialog-actions>
</section>
