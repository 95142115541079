<app-modal-header title="Respond to HTTP caller" *ngIf="data.integration === 'respond'" (close)="dialogRef.close()"></app-modal-header>
<app-modal-header title="DataMorf workflow" *ngIf="data.integration === 'datamorf'" (close)="dialogRef.close()"></app-modal-header>
<app-modal-header title="Waterfall enrichment" *ngIf="data.integration === 'datamorf-waterfall'" (close)="dialogRef.close()"></app-modal-header>

<mat-dialog-content>
  <div class="text-gray-400 text-sm">

    <div [ngSwitch]="data.integration">
      <div *ngSwitchCase="'respond'">
        <p class="mb-3">
          Send the resulting data back to the HTTP caller.
        </p>
        <p class="mb-3">
          This integration is useful when you need to respond to an incoming HTTP request with a specific payload.
        </p>
      </div>
      <div *ngSwitchCase="'datamorf'">
        <p class="mb-3">
          Trigger another DataMorf workflow with ease. This integration allows you to chain multiple workflows together, enabling you to build complex data processing pipelines.
        </p>
        <p class="mb-3">
          This is useful when you need to perform multiple operations on the same data, or when you need to enrich the data further using different services.
        </p>
      </div>
      <div *ngSwitchCase="'datamorf-waterfall'">
        <div class="mb-5">
          <strong class="font-bold text-lg">Waterfall enrichment out of the box.</strong>
          <small class="float-right text-xs m-2">
            Powered by
            <a href="https://waterfall.io" title="Waterfall.io" target="_blank">
              <img src="./assets/img/vendors/waterfall_long.svg" alt="Waterfall.io" class="inline-block h-8">
            </a>
          </small>
        </div>
        <p class="mb-3">
          Waterfall data enrichment is a process where data is progressively enhanced by passing through multiple stages, each adding more value or context. This method ensures that even if some data points are incomplete initially, they are enriched as they progress through the workflow, leveraging various data sources and APIs to fill in the gaps.
        </p>
        <div class="mb-3">
          <p>This service is charged separately, and the prices are as follows:</p>
          <table class="table-auto w-full text-left">
            <thead>
            <tr>
              <th class="px-4 py-2">Service</th>
              <th class="px-4 py-2">Cost</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="border px-4 py-2">Contact Enrichment</td>
              <td class="border px-4 py-2">$0.15 per contact</td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Company Enrichment</td>
              <td class="border px-4 py-2">$0.13 per company</td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Phone Enrichment</td>
              <td class="border px-4 py-2">$0.35 per contact</td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Company Prospecting</td>
              <td class="border px-4 py-2">$0.15 per contact found</td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Email Verifier</td>
              <td class="border px-4 py-2">$0.03 per email</td>
            </tr>
            </tbody>
          </table>
          <p class="mt-2">
            <i class="fas fa-info-circle mr-1 text-primary-900"></i>
            Only billed for successful enrichments. Failed enrichments are not charged.
          </p>
        </div>
      </div>
      <div *ngSwitchCase="'zerobounce'">
        <div class="mb-5">
          <strong class="font-bold text-lg">Email validation out of the box.</strong>
          <small class="float-right text-xs m-2">
            Powered by
            <a href="https://zerobounce.com" title="ZerBounce" target="_blank">
              <img src="./assets/img/vendors/zerobounce-datamorf.png" alt="ZeroBounce" class="inline-block h-8">
            </a>
          </small>
        </div>
        <div class="mb-3">
          <p>This service is charged separately, and the prices are as follows:</p>
          <table class="table-auto w-full text-left">
            <thead>
            <tr>
              <th class="px-4 py-2">Service</th>
              <th class="px-4 py-2">Cost</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="border px-4 py-2">Email validation</td>
              <td class="border px-4 py-2">$0.005 per email</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div *ngSwitchCase="'linkedin-scraper'">
        <p class="mb-3">
          Scrape a LinkedIn page to extract the public data in it.
        </p>
        <div class="mb-3">
          <p>This service is charged separately, and the prices are as follows:</p>
          <table class="table-auto w-full text-left">
            <thead>
            <tr>
              <th class="px-4 py-2">Service</th>
              <th class="px-4 py-2">Cost</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="border px-4 py-2">Profile scraper</td>
              <td class="border px-4 py-2">$0.01 per scrape</td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Company page scraper</td>
              <td class="border px-4 py-2">$0.01 per scrape</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div *ngSwitchCase="'instagram-scraper'">
        <p class="mb-3">
          Scrape an Instagram page to extract all the public data in it.
        </p>
        <div class="mb-3">
          <p>This service is charged separately, and the prices are as follows:</p>
          <table class="table-auto w-full text-left">
            <thead>
            <tr>
              <th class="px-4 py-2">Service</th>
              <th class="px-4 py-2">Cost</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="border px-4 py-2">Profile scraper</td>
              <td class="border px-4 py-2">$0.005 per scrape</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div *ngSwitchCase="'web-unlocker'">
        <p class="mb-3">
          Unlock any website you want to scrape with ease. Automated scraping for the hardest sites, leveraging Residential proxies, solving CAPTCHAs, rendering JS, using custom fingerprints and cookies.
        </p>
        <div class="mb-3">
          <p>This service is charged separately, and the prices are as follows:</p>
          <table class="table-auto w-full text-left">
            <thead>
            <tr>
              <th class="px-4 py-2">Request</th>
              <th class="px-4 py-2">Cost</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td class="border px-4 py-2">Successful</td>
              <td class="border px-4 py-2">$0.01</td>
            </tr>
            <tr>
              <td class="border px-4 py-2">Not successful</td>
              <td class="border px-4 py-2">No cost</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>


</mat-dialog-content>

<mat-dialog-actions class="mt-auto">
  <app-modal-footer class="w-full" (onClose)="dialogRef.close()"></app-modal-footer>
</mat-dialog-actions>
