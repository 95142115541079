import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {NgIf, NgSwitch, NgSwitchCase} from '@angular/common';
import {ModalHeaderComponent} from '../../../layout/modal-header/modal-header.component';
import {CdkScrollable} from '@angular/cdk/scrolling';
import {ModalFooterComponent} from '../../../layout/modal-footer/modal-footer.component';

@Component({
    selector: 'app-info-standard-integration',
    templateUrl: './info-standard-integration.component.html',
    styleUrls: ['./info-standard-integration.component.scss'],
    standalone: true,
    imports: [NgIf, ModalHeaderComponent, CdkScrollable, MatDialogContent, NgSwitch, NgSwitchCase, MatDialogActions, ModalFooterComponent]
})
export class InfoStandardIntegrationComponent {
  constructor(
    public dialogRef: MatDialogRef<InfoStandardIntegrationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      integration: string,
    },
  ) { }
}
