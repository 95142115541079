<section class="h-full flex flex-col ">
  <app-modal-header [title]="data.computation.name" (close)="dialogRef.close()"></app-modal-header>

  <mat-dialog-content>

    <div class="text-gray-500 mb-4">
      This is a usage example for the computation <strong>{{data.computation.name}}</strong>.
    </div>

    <div class="mb-5">
      {{data.computation.description}}
    </div>

    <div [ngSwitch]="data.computation.id">
      <!-- Constant -->
      <div *ngSwitchCase="'constant'">
        <p class="mb-1">This is the simplest computation possible. You define a value and we will add it to the final result.</p>
        <p class="mb-1">Currently you can add 3 types of values: string, number or boolean. Select your type in the <code>Constant type</code> drop down box and then type your desired value in the following input.</p>
      </div>

      <!-- Make id -->
      <div *ngSwitchCase="'makeId'">
        <p class="mb-1">No inputs required for this computation mode. However, we need one parameter: id length.</p>
        <p class="mb-1">The id generated will contain lower and uppercase letters and numbers. Example:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>k5Djft3dDkk9u4nR5ja</li>
          <li>j5gAedm</li>
          <li>592GtqBKExU8a</li>
        </ol>
      </div>

      <!-- Max -->
      <div *ngSwitchCase="'max'">
        <p class="mb-1">Example inputs:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>Clearbit | company | metrics.employees</li>
          <li>Cognism | company | size_from</li>
          <li>Adapt IO | company | companyHeadCount</li>
        </ol>

        <p class="mb-1">Which will give these values:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>53</li>
          <li>50</li>
          <li>61</li>
        </ol>

        <p class="mb-1">
          The result of the computation will consist of only the highest value from the list. In this case our result would be <strong>61</strong>.
        </p>
      </div>

      <!-- Min -->
      <div *ngSwitchCase="'min'">
        <p class="mb-1">Example inputs:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>Clearbit | company | metrics.employees</li>
          <li>Cognism | company | size_from</li>
          <li>Adapt IO | company | companyHeadCount</li>
        </ol>

        <p class="mb-1">Which will give these values:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>53</li>
          <li>50</li>
          <li>61</li>
        </ol>

        <p class="mb-1">
          The result of the computation will consist of only the lowest value from the list. In this case our result would be <strong>50</strong>.
        </p>
      </div>

      <!-- Priority -->
      <div *ngSwitchCase="'priority'">
        <p class="mb-1">Example inputs:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>Incoming data | employee_count</li>
          <li>Clearbit | Company | metrics.employees</li>
          <li>Adapt IO | Company | companyHeadCount</li>
        </ol>

        <p class="mb-1">Which will give these values:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>null</li>
          <li>50</li>
          <li>61</li>
        </ol>

        <p class="mb-1">
          The result of the computation will be the first non empty value. In this case our result would be <strong>50</strong>.
        </p>
      </div>

      <!-- Join inputs -->
      <div *ngSwitchCase="'joinInputs'">
        <p class="mb-1">Example inputs:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>Clearbit | company | metrics.employees</li>
          <li>Cognism | company | size_from</li>
          <li>Adapt IO | company | companyHeadCount</li>
        </ol>

        <p class="mb-1">Which will give these values:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>55</li>
          <li>50</li>
          <li>61</li>
        </ol>

        <p class="mb-1">Using separator: <code> - </code></p>
        <p class="mb-1">
          The result of the computation will be all these values concatenated. In this case our result would be <strong>55 - 50 - 61</strong>.
        </p>
      </div>

      <!-- Most frequent -->
      <div *ngSwitchCase="'mostFrequent'">
        <p class="mb-1">Example inputs:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>Clearbit | company | metrics.employees</li>
          <li>Cognism | company | size_from</li>
          <li>Adapt IO | company | companyHeadCount</li>
          <li>ZoomInfo | company | employees</li>
        </ol>

        <p class="mb-1">Which will give these values:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>50</li>
          <li>55</li>
          <li>50</li>
          <li>61</li>
        </ol>

        <p class="mb-1">
          The result of the computation will return the most frequent value. In this case our result would be <strong>50</strong>.
        </p>
        <p class="text-sm text-gray-400">
          Note: if there is not a single moat frequent value, it will return the first one according to the inputs priority list.
        </p>
      </div>

      <!-- Least frequent -->
      <div *ngSwitchCase="'leastFrequent'">
        <p class="mb-1">Example inputs:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>Clearbit | company | metrics.employees</li>
          <li>Cognism | company | size_from</li>
          <li>Adapt IO | company | companyHeadCount</li>
          <li>ZoomInfo | company | employees</li>
        </ol>

        <p class="mb-1">Which will give these values:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>50</li>
          <li>55</li>
          <li>50</li>
          <li>50</li>
        </ol>

        <p class="mb-1">
          The result of the computation will return the most frequent value. In this case our result would be <strong>55</strong>.
        </p>
        <p class="text-sm text-gray-400">
          Note: if there is not a single least frequent value, it will return the first one according to the inputs priority list.
        </p>
      </div>

      <!-- Join -->
      <div *ngSwitchCase="'join'">
        <p class="mb-1">Expects an array as an input value.</p>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>Clearbit | company | tags</li>
        </ol>

        <p class="mb-1">Which will give this value:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li><code>['tech', 'google cloud', 'payments']</code></li>
        </ol>

        <p class="mb-1">Using separator: <code> - </code></p>
        <p class="mb-1">
          The result of the computation will return the array concatenated. In this case our result would be <strong>tech - google cloud - payments</strong>.
        </p>
        <p class="text-sm text-gray-400 mt-4">
          Note: If the input value is not an array, this computation will not work as expected.
        </p>
      </div>

      <!-- Split -->
      <div *ngSwitchCase="'split'">
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | form_name</li>
        </ol>

        <p class="mb-1">Which will give this value:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li><code>Request - Website - Any form name here</code></li>
        </ol>

        <p class="mb-1">Using separator: <code> - </code></p>
        <p class="mb-1">Using index: <code>2</code></p>
        <p class="mb-1">
          The result of the computation will split the string and return the value from the index position. In this case our result would be <strong>Website</strong>.
        </p>
        <p class="text-sm text-gray-400 mt-4">
          Note: If no index is provided, the full array will be returned.
        </p>
      </div>

      <!-- Stringify -->
      <div *ngSwitchCase="'stringify'">
        <p class="mb-1">Simply convert any value to a string. It uses the JS method <code>.toString()</code>.</p>
      </div>

      <!-- Item in list -->
      <div *ngSwitchCase="'itemInList'">
        <p class="mb-1">Expects an array of objects as an input value.</p>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | datapoints</li>
        </ol>

        <p class="mb-1">Which will give this value:</p>
        <app-pretty-print-json [object]="itemInList"></app-pretty-print-json>

        <p class="mb-1">Using key: <code>datapoint</code></p>
        <p class="mb-1">Using value: <code>website</code></p>
        <p class="mb-1">
          The result of the computation will find the array element with datapoint === website and return the full object.
          In this case our result would be:
        </p>
        <app-pretty-print-json [object]="itemInList[2]"></app-pretty-print-json>
        <p class="text-sm text-gray-400 mt-4">
          Note: if no key - value can be matched, it won't return any value.
        </p>
      </div>

      <!-- nth array item -->
      <div *ngSwitchCase="'nthArrayItem'">
        <p class="mb-1">Expects an array as an input value.</p>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | datapoints</li>
        </ol>

        <p class="mb-1">Which will give this value:</p>
        <app-pretty-print-json [object]="itemInList"></app-pretty-print-json>

        <p class="mb-1">Using index: <code>1</code></p>
        <p class="mb-1">
          The result of the computation will get the n-th array element.
          In this case our result would be:
        </p>
        <app-pretty-print-json [object]="itemInList[1]"></app-pretty-print-json>
      </div>

      <!-- Find first occurrence -->
      <div *ngSwitchCase="'findFirstOccurrence'">
        <p class="mb-1">Expects an array of strings as an input value.</p>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | tags</li>
        </ol>

        <p class="mb-1">Which will give this value:</p>
        <app-pretty-print-json [object]="['tag 1', 'tag 4', 'tag 5', 'tag 8', 'tag 11']"></app-pretty-print-json>

        <p class="mb-1">Using list values:</p>
        <app-pretty-print-json [object]="['tag 3', 'tag 5', 'tag 8', 'tag 12']"></app-pretty-print-json>

        <p class="mb-1">
          The result of the computation will try to get the first value in the input that is found in our list.
          In this case our result would be
          <br>
          <app-pretty-print-json [object]="'tag 8'"></app-pretty-print-json>
<!--          <code>tag 8</code>-->
        </p>
      </div>

      <!-- Extract URL param -->
      <div *ngSwitchCase="'extractUrlParam'">
        <p class="mb-1">Expects a string as input value.</p>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | form_url</li>
        </ol>

        <p class="mb-1">Which will give this value:</p>
        <app-pretty-print-json [object]="'https://datamorf.io/some-form-page?utm_campaign=campaign-1&utm_medium=email&some_other_param=some_value'"></app-pretty-print-json>

        <p class="mb-1">Using URL parameter: <code>utm_campaign</code></p>
        <p class="mb-1">
          The result of the computation will find the url parameter in a given string.
          In this case our result would be:
        </p>
        <app-pretty-print-json [object]="'campaign-1'"></app-pretty-print-json>
        <p class="text-sm text-gray-400 mt-4">
          Note: if the string is not a url or a parameter cannot be found, it won't return any value.
        </p>
      </div>


      <!-- URL Encode -->
      <div *ngSwitchCase="'urlEncode'">
        <p class="mb-1">Expects a string as input value.</p>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | text</li>
        </ol>

        <p class="mb-1">Which will give this value:</p>
        <app-pretty-print-json [object]="'This string contains spëcial châracters'"></app-pretty-print-json>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'This%20string%20contains%20sp%C3%ABcial%20ch%C3%A2racters'"></app-pretty-print-json>
      </div>

      <!-- URL decode -->
      <div *ngSwitchCase="'urlDecode'">
        <p class="mb-1">Expects a string as input value.</p>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | encoded_text</li>
        </ol>

        <p class="mb-1">Which will give this value:</p>
        <app-pretty-print-json [object]="'This%20string%20contains%20sp%C3%ABcial%20ch%C3%A2racters'"></app-pretty-print-json>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'This string contains spëcial châracters'"></app-pretty-print-json>
      </div>

      <!-- To lower -->
      <div *ngSwitchCase="'toLower'">
        <p class="mb-1">Expects a string as input value.</p>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | some_text</li>
        </ol>

        <p class="mb-1">Which will give this value:</p>
        <app-pretty-print-json [object]="'The brown FOX jumps over the lazy DOG'"></app-pretty-print-json>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'the brown fox jumps over the lazy dog'"></app-pretty-print-json>
      </div>

      <!-- To upper -->
      <div *ngSwitchCase="'toUpper'">
        <p class="mb-1">Expects a string as input value.</p>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | some_text</li>
        </ol>

        <p class="mb-1">Which will give this value:</p>
        <app-pretty-print-json [object]="'The brown FOX jumps over the lazy DOG'"></app-pretty-print-json>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'THE BROWN FOX JUMPS OVER THE LAZY DOG'"></app-pretty-print-json>
      </div>

      <!-- To title -->
      <div *ngSwitchCase="'toTitle'">
        <p class="mb-1">Expects a string as input value.</p>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | some_text</li>
        </ol>

        <p class="mb-1">Which will give this value:</p>
        <app-pretty-print-json [object]="'The brown FOX jumps over the lazy DOG'"></app-pretty-print-json>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'The Brown Fox Jumps Over The Lazy Dog'"></app-pretty-print-json>
      </div>

      <!-- To snake -->
      <div *ngSwitchCase="'toSnake'">
        <p class="mb-1">Expects a string as input value.</p>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | some_text</li>
        </ol>

        <p class="mb-1">Which will give this value:</p>
        <app-pretty-print-json [object]="'The brown FOX jumps over the lazy DOG'"></app-pretty-print-json>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'the_brown_fox_jumps_over_the_lazy_dog'"></app-pretty-print-json>
      </div>

      <!-- To kebab -->
      <div *ngSwitchCase="'toKebab'">
        <p class="mb-1">Expects a string as input value.</p>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | some_text</li>
        </ol>

        <p class="mb-1">Which will give this value:</p>
        <app-pretty-print-json [object]="'The brown FOX jumps over the lazy DOG'"></app-pretty-print-json>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'the-brown-fox-jumps-over-the-lazy-dog'"></app-pretty-print-json>
      </div>

      <!-- To camel -->
      <div *ngSwitchCase="'toCamel'">
        <p class="mb-1">Expects a string as input value.</p>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | some_text</li>
        </ol>

        <p class="mb-1">Which will give this value:</p>
        <app-pretty-print-json [object]="'The brown FOX jumps over the lazy DOG'"></app-pretty-print-json>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'theBrownFoxJumpsOverTheLazyDog'"></app-pretty-print-json>
      </div>

      <!-- Wrap -->
      <div *ngSwitchCase="'wrapString'">
        <p class="mb-1">Expects a string as input value.</p>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | some_text</li>
        </ol>

        <p class="mb-1">Which will give this value:</p>
        <app-pretty-print-json [object]="'The brown FOX jumps over the lazy DOG'"></app-pretty-print-json>

        <p class="mb-1">Using leading wrap value: <code>[</code></p>
        <p class="mb-1">Using trailing wrap value: <code>]</code></p>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'[The brown FOX jumps over the lazy DOG]'"></app-pretty-print-json>
      </div>

      <!-- Execution id -->
      <div *ngSwitchCase="'executionId'">
        <p class="mb-1">No inputs required.</p>

        <p class="mb-1">
          The result will be something like this:
        </p>
        <app-pretty-print-json [object]="executionId"></app-pretty-print-json>
      </div>

      <!-- Dictionary -->
      <div *ngSwitchCase="'dictionary'">
        <p class="mb-1">Expects a string as an input value.</p>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | key</li>
        </ol>
        <p class="mb-1">Which will give this value:</p>
        <app-pretty-print-json [object]="'key-3'"></app-pretty-print-json>

        <p class="mb-1">Using this table as our dictionary:</p>
        <table class="table-auto w-full shadow rounded my-4">
          <thead class="border-b text-left">
            <tr>
              <th class="p-1">Key</th>
              <th class="p-1">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="p-1">key-1</td>
              <td class="p-1">Value 1</td>
            </tr>
            <tr>
              <td class="p-1">key-2</td>
              <td class="p-1">Value 2</td>
            </tr>
            <tr>
              <td class="p-1">key-3</td>
              <td class="p-1">Value 3</td>
            </tr>
            <tr>
              <td class="p-1">key-4</td>
              <td class="p-1">Value 4</td>
            </tr>
          </tbody>
        </table>
        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'Value 3'"></app-pretty-print-json>
      </div>

      <!-- Sort list -->
      <div *ngSwitchCase="'sortList'">
        <p class="mb-1">Expects a list as an input value. It can be a list of strings, numbers, objects...</p>

        <h3 class="text-xl font-bold mt-5">Example 1</h3>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | list</li>
        </ol>
        <p class="mb-1">Which will give this value:</p>
        <app-pretty-print-json [object]="list"></app-pretty-print-json>

        <p class="mb-1">Using sorting property: <code>timestamp</code>.</p>
        <p class="mb-1">Using sorting direction: <code>descendant</code>.</p>
        <p class="mb-1">Getting first list element (index): <code>0</code>.</p>
        <p class="mb-1">Using result item property <code>listId</code>.</p>

        <div class="my-3">
          The computation will:
          <ul class="pl-4 list-disc">
            <li>Sort the list using the timestamp property (descendant, from high to low).</li>
            <li>Get the index <code>0</code> from the sorted list (remember that list indexes start at 0).</li>
            <li>From that first element, obtain the value of the property <code>listId</code></li>
          </ul>
        </div>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="1440"></app-pretty-print-json>


        <h3 class="text-xl font-bold mt-5">Example 2</h3>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | list_2</li>
        </ol>
        <p class="mb-1">Which will give this value:</p>
        <app-pretty-print-json [object]="list2"></app-pretty-print-json>

        <p class="mb-1">No sorting property is used.</p>
        <p class="mb-1">Sorting direction: <code>ascendant</code>.</p>
        <p class="mb-1">Getting second list element (index): <code>1</code>.</p>

        <div class="my-3">
          The computation will:
          <ul class="pl-4 list-disc">
            <li>Sort the list (ascendant, alphabetically).</li>
            <li>Get the index <code>1</code> from the sorted list (remember that list indexes start at 0, so index 1 is the second element).</li>
          </ul>
        </div>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'Apple'"></app-pretty-print-json>

        <p class="text-sm text-gray-400 mt-4">
          Note: if the list contains objects, you must provide a sorting property.
        </p>
      </div>

      <!-- Dot notation path -->
      <div *ngSwitchCase="'path'">
        <p class="mb-1">Expects an object as an input value.</p>

        <h3 class="text-xl font-bold mt-5">Example</h3>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | user</li>
        </ol>
        <p class="mb-1">Which will give this value:</p>
        <app-pretty-print-json [object]="object"></app-pretty-print-json>

        <p class="mb-1">Using path property: <code>user.name.first</code></p>
        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'John'"></app-pretty-print-json>
      </div>

      <!-- Make array with inputs -->
      <div *ngSwitchCase="'makeArrayWithInputs'">
        <h3 class="text-xl font-bold mt-5">Example</h3>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | firstname</li>
          <li>incoming_payload | lastname</li>
        </ol>
        <p class="mb-1">Which will give these values:</p>
        <app-pretty-print-json [object]="{
          firstname: 'John'
        }"></app-pretty-print-json>
        <app-pretty-print-json [object]="{
          lastname: 'Doe'
        }"></app-pretty-print-json>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="['John', 'Doe']"></app-pretty-print-json>
      </div>

      <!-- Extract property from array of objects -->
      <div *ngSwitchCase="'extractPropertyFromArrayOfObjects'">
        <p class="mb-1">Expects a list as an input value.</p>

        <h3 class="text-xl font-bold mt-5">Example</h3>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>incoming_payload | lists</li>
        </ol>
        <p class="mb-1">Which will give this value:</p>
        <app-pretty-print-json [object]="list"></app-pretty-print-json>

        <p class="mb-1">Using path property (you can use dot notation here): <code>listId</code></p>
        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="[1574, 504, 1440, 354]"></app-pretty-print-json>
      </div>

      <!-- GPT Prompt -->
      <div *ngSwitchCase="'gptPrompt'">
        <p class="mb-1">Expects a prompt that will be sent to GPT for AI processing.</p>
        <p class="mb-1" *ngIf="false">You can use your own API key or use DataMorf's one (extra costs may apply).</p>

        <h3 class="text-xl font-bold mt-5">How to use it</h3>
        <p class="mb-1">
          Write your desired prompt in the text box.
          <br>
          You can include your previously computed variables typing a double
          braces key: <code ngNonBindable class="whitespace-nowrap">{{</code>.
        </p>

        <h3 class="text-xl font-bold mt-5">Practical example</h3>
        <p class="mb-1">Some of our previous computations:</p>
        <table class="basic-table text-sm text-left mb-5">
          <tr>
            <th>Computation name</th>
            <th>Example value</th>
          </tr>
          <tbody>
            <tr>
              <th class="font-light">First Name</th>
              <th class="font-light">John</th>
            </tr>
            <tr>
              <th class="font-light">Location</th>
              <th class="font-light">Barcelona</th>
            </tr>
            <tr>
              <th class="font-light">Company Name</th>
              <th class="font-light">DataMorf</th>
            </tr>
          </tbody>
        </table>

        <p class="mb-1">And our prompt can be something like this:</p>
        <div ngNonBindable class="code mb-3 px-0">
          Hey GPT, complete the following sentence including a fun fact about the city:
          <br>
          Hey <span class="interpolation">First Name</span>!
          <br>
          I've recently visited <span class="interpolation">Location</span> and I realised your company
          <span class="interpolation">Company Name</span> is based there. It was very interesting because...
        </div>
        <p class="mb-1">Finally, the processed prompt that will be sent to GPT will look like this:</p>
        <div ngNonBindable class="code mb-3">
          Hey GPT, complete the following sentence including a fun fact about the city:
          <br>
          Hey John!
          <br>
          I've recently visited Barcelona and I realised your company DataMorf is based there. It was
          very interesting because...
        </div>

        <p class="mb-1">The last step is handled by GPT, and results will vary depending on the prompt generated.</p>

        <p class="text-sm text-gray-400 mt-4">
          Note: if a computation value is not found, no value will be used.
        </p>
      </div>

      <!-- Extract domain from an email -->
      <div *ngSwitchCase="'extractEmailDomain'">
        <h3 class="text-xl font-bold mt-5">Expects an email as an input</h3>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>Incoming payload | contact_email</li>
        </ol>
        <p class="mb-1">Which will give these values:</p>
        <app-pretty-print-json [object]="'info@datamorf.io'"></app-pretty-print-json>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'datamorf.io'"></app-pretty-print-json>
      </div>

      <!-- Extract name from an email -->
      <div *ngSwitchCase="'extractEmailName'">
        <h3 class="text-xl font-bold mt-5">Expects an email as an input</h3>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>Incoming payload | contact_email</li>
        </ol>
        <p class="mb-1">Which will give these values:</p>
        <app-pretty-print-json [object]="'info@datamorf.io'"></app-pretty-print-json>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'info'"></app-pretty-print-json>
      </div>

      <!-- Get domain name -->
      <div *ngSwitchCase="'getDomainName'">
        <h3 class="text-xl font-bold mt-5">Expects a URL as input.</h3>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>Incoming payload | website</li>
        </ol>
        <p class="mb-1">Which will give these values:</p>
        <app-pretty-print-json [object]="'https://www.datamorf.io/guides/get-started?utm_medium=email'"></app-pretty-print-json>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'datamorf.io'"></app-pretty-print-json>
      </div>

      <!-- Get top level domain -->
      <div *ngSwitchCase="'getTopLevelDomain'">
        <h3 class="text-xl font-bold mt-5">Expects a URL as input.</h3>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>Incoming payload | website</li>
        </ol>
        <p class="mb-1">Which will give these values:</p>
        <app-pretty-print-json [object]="'https://www.datamorf.io/guides/get-started?utm_medium=email'"></app-pretty-print-json>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'io'"></app-pretty-print-json>
      </div>

      <!-- Get second level domain -->
      <div *ngSwitchCase="'getSecondLevelDomain'">
        <h3 class="text-xl font-bold mt-5">Expects a URL as input.</h3>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>Incoming payload | website</li>
        </ol>
        <p class="mb-1">Which will give these values:</p>
        <app-pretty-print-json [object]="'https://www.datamorf.io/guides/get-started?utm_medium=email'"></app-pretty-print-json>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'datamorf'"></app-pretty-print-json>
      </div>

      <!-- Get host name -->
      <div *ngSwitchCase="'getHostName'">
        <h3 class="text-xl font-bold mt-5">Expects a URL as input.</h3>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>Incoming payload | website</li>
        </ol>
        <p class="mb-1">Which will give these values:</p>
        <app-pretty-print-json [object]="'https://www.datamorf.io/guides/get-started?utm_medium=email'"></app-pretty-print-json>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'www.datamorf.io'"></app-pretty-print-json>
      </div>

      <!-- Is valid email -->
      <div *ngSwitchCase="'isValidEmail'">
        <h3 class="text-xl font-bold mt-5">Expects an email as input.</h3>
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>Incoming payload | contact_email</li>
        </ol>
        <p class="mb-1">Which will give these values:</p>
        <app-pretty-print-json [object]="'info@datamorf.io'"></app-pretty-print-json>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="true"></app-pretty-print-json>

        <p class="mb-1 mt-5">Instead, if the email value is:</p>
        <app-pretty-print-json [object]="'2info@datamorf.io'"></app-pretty-print-json>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="false"></app-pretty-print-json>
        <p>
          Because the email does not have a valid formatting.
        </p>
      </div>

      <!-- Normalizer -->
      <div *ngSwitchCase="'isValidEmail'">
        <p class="mb-1">Example input:</p>
        <ol class="mb-3 list-decimal pl-6">
          <li>Incoming payload | company_name</li>
        </ol>
        <p class="mb-1">Which will give these values:</p>
        <app-pretty-print-json [object]="'DATAMORF.IO'"></app-pretty-print-json>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'Datamorf'"></app-pretty-print-json>

        <p class="mb-1 mt-5">Another example:</p>
        <app-pretty-print-json [object]="'datamorf.io ltd'"></app-pretty-print-json>

        <p class="mb-1">
          The result would be:
        </p>
        <app-pretty-print-json [object]="'Datamorf'"></app-pretty-print-json>
      </div>

      <div *ngSwitchDefault class="italic text-gray-500 text-sm">
        This computation doesn't have any example yet. We are working hard to have a good documentation. Sorry for the inconvenience.
      </div>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions class="mt-auto">
    <app-modal-footer class="w-full" (onClose)="dialogRef.close()"></app-modal-footer>
  </mat-dialog-actions>
</section>
