<section class="h-full flex flex-col">
  <app-modal-header title="Feedback / Report a bug" (close)="dialogRef.close()"></app-modal-header>

  <mat-dialog-content>
    <form [formGroup]="reportForm">
      <mat-form-field class="w-full" appearance="outline">
        <mat-label>Subject</mat-label>
        <input matInput formControlName="subject">
      </mat-form-field>

      <mat-form-field class="w-full" appearance="outline">
        <mat-label>Type</mat-label>
        <mat-select formControlName="type">
          <mat-option value="help">Need help</mat-option>
          <mat-option value="feedback">Give feedback</mat-option>
          <mat-option value="report-bug">Report error</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="w-full" appearance="outline">
        <mat-label>We are all ears 👂</mat-label>
        <textarea matInput formControlName="message" rows="5"></textarea>
      </mat-form-field>

    </form>

    <p class="p-6 bg-accent-50 rounded-xl text-gray-800" *ngIf="error">
      <i class="fas fa-exclamation-triangle mr-1 text-red-600"></i>
      So ironic... The "report an error" function is currently unavailable. We
      apologize for the inconvenience and promise to get it back up and running ASAP.
    </p>
    <p class="p-6 bg-secondary-50 rounded-xl text-gray-800" *ngIf="success">
      <i class="fas fa-check-circle mr-1 text-green-600"></i>
      Feedback sent! We will action it ASAP. Thank you!
    </p>


  </mat-dialog-content>

  <mat-dialog-actions class="mt-auto">
    <app-modal-footer class="w-full" (onClose)="dialogRef.close()" [send]="!error && !success" (onSend)="report()"
                      [processing]="submitting"></app-modal-footer>
  </mat-dialog-actions>

</section>
