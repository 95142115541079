import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {ModalHeaderComponent} from '../../../layout/modal-header/modal-header.component';
import {CdkScrollable} from '@angular/cdk/scrolling';
import {ModalFooterComponent} from '../../../layout/modal-footer/modal-footer.component';

@Component({
    selector: 'app-info-slack-channel',
    templateUrl: './info-slack-channel.component.html',
    styleUrls: ['./info-slack-channel.component.scss'],
    standalone: true,
    imports: [ModalHeaderComponent, CdkScrollable, MatDialogContent, MatDialogActions, ModalFooterComponent]
})
export class InfoSlackChannelComponent {
  constructor(
    public dialogRef: MatDialogRef<InfoSlackChannelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {},
  ) { }
}
